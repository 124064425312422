package com.pkg_dot_zip.kobwebportfoliopkgdotzip.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page("/")
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.layouts.MarkdownLayout("About") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("pkg-dot-zip") }) {
                org.jetbrains.compose.web.dom.Text("pkg-dot-zip")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Student Technische Informatica, Avans Breda")
                }
                org.jetbrains.compose.web.dom.Br()
                org.jetbrains.compose.web.dom.Text("Alphen, Noord-Brabant, Netherlands")
            }
            org.jetbrains.compose.web.dom.P {
                com.varabyte.kobweb.silk.components.navigation.Link("https://www.linkedin.com/in/pkg-dot-zip") {
                    org.jetbrains.compose.web.dom.Text("LinkedIn Profile")
                }
                org.jetbrains.compose.web.dom.Br()
                org.jetbrains.compose.web.dom.Text("Email: ")
                com.varabyte.kobweb.silk.components.navigation.Link("mailto:simon@pkg-dot-zip.com") {
                    org.jetbrains.compose.web.dom.Text("simon@pkg-dot-zip.com")
                }
            }
            com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
            org.jetbrains.compose.web.dom.H2(attrs = { id("about-me") }) {
                org.jetbrains.compose.web.dom.Text("About Me")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hello! I’m Simon, a 22-year-old student currently pursuing a degree in ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Technische Informatica")
                }
                org.jetbrains.compose.web.dom.Text(" at Avans University of Applied Sciences in Breda. My journey combines a strong theoretical foundation with practical software engineering skills. I’m passionate about developing efficient and user-friendly software systems that solve real-world problems.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("programming-languages-and-skills") }) {
                org.jetbrains.compose.web.dom.Text("Programming Languages and Skills")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I have cultivated a robust skill set across various programming languages, including:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Java")
                    }
                    org.jetbrains.compose.web.dom.Text(" and ")
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Kotlin")
                    }
                    org.jetbrains.compose.web.dom.Text(" for robust backend development")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("C++")
                    }
                    org.jetbrains.compose.web.dom.Text(", ")
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("C")
                    }
                    org.jetbrains.compose.web.dom.Text(", and ")
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("C#")
                    }
                    org.jetbrains.compose.web.dom.Text(" for low-level programming and systems development")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Python")
                    }
                    org.jetbrains.compose.web.dom.Text(" for rapid prototyping and scripting tasks")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("JavaScript")
                    }
                    org.jetbrains.compose.web.dom.Text(" for web development projects")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Additionally, I’m experienced in crafting ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("class diagrams")
                }
                org.jetbrains.compose.web.dom.Text(", writing ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("technical documentation")
                }
                org.jetbrains.compose.web.dom.Text(", and ensuring software systems are scalable and efficient.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("passion-for-communication-and-language-learning") }) {
                org.jetbrains.compose.web.dom.Text("Passion for Communication and Language Learning")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Beyond coding, I have a keen interest in languages and communication. I’ve studied ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Russian")
                }
                org.jetbrains.compose.web.dom.Text(", reaching an elementary proficiency, and completed a ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Dutch Sign Language")
                }
                org.jetbrains.compose.web.dom.Text(" course. These experiences expanded my cultural awareness and enriched my ability to communicate in diverse settings.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Moreover, I have hands-on teaching experience from a 5-month internship at a primary school, where I honed my skills in explaining complex concepts in simple terms—an essential trait in technology when guiding others or presenting to non-technical stakeholders.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("career-aspirations") }) {
                org.jetbrains.compose.web.dom.Text("Career Aspirations")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I am constantly learning new methodologies and technologies in the software engineering field. I’m actively seeking internships or projects where I can apply my skills, gain insights from seasoned professionals, and contribute to innovative and impactful solutions.")
            }
            com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Feel free to reach out by ")
                com.varabyte.kobweb.silk.components.navigation.Link("mailto:simon@pkg-dot-zip.com") {
                    org.jetbrains.compose.web.dom.Text("email")
                }
                org.jetbrains.compose.web.dom.Text(" if you’d like to chat about technology, education, or to share ideas!")
            }
        }
    }
}
