package com.pkg_dot_zip.kobwebportfoliopkgdotzip.util

class Logger(var className: String) {
    private fun printLog(msg: String) = println(msg)

    fun trace(str: String) = printLog("\uD83D\uDD0E $className - $str")
    fun info(str: String) = printLog("\uD83D\uDDA8\uFE0F $className - $str")
    fun debug(str: String) = printLog("\uD83E\uDDEA $className - $str")
    fun warning(str: String) = printLog("⚠\uFE0F $className - $str")
    fun error(str: String) = printLog("⛔ $className - $str")
}