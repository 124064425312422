package com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.sections

import AppearanceAwareImage
import androidx.compose.runtime.Composable
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.FontHandler
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.Res
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.widgets.GlassBox
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.components.widgets.IconButtonNoHover
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.Logger
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun SoftwareAndSkills() {
    // These are the boxes we want to create.
    val data = getToolBoxAreas()

//    H1 { SpanText("I Work With...", modifier = Modifier.fontFamily(FontHandler.getFont("h1"))) }

    SimpleGrid(numColumns(1, 1, 2, 2, 2)) {
        data.forEach { createGlassBoxContainer(it) }
    }
}

@Composable
fun createGlassBoxContainer(area: ToolsBoxArea) {
    val logger = Logger("createGlassBoxContainer")

    val ctx = rememberPageContext()

    logger.info("Creating Box Container for: ${area.name}")

    Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.Top) {
        Column(horizontalAlignment = Alignment.CenterHorizontally) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                area.icon()
                SpanText(area.name, modifier = Modifier.fontFamily(FontHandler.getFont("h1")).padding(left = 4.px))
            }

            Row {
                GlassBox(
                    modifier = Modifier.margin(all = 2.cssRem)
                ) {
                    fun getAmountInGrid() : Int {
                        logger.trace("InnerWidth: ${window.innerWidth}")
                        logger.trace("OuterWidth: ${window.outerWidth}")

                        val needsTwoItemsWidth = 366
                        val needsOneItemsWidth = 281

                        if (window.innerWidth < needsOneItemsWidth) return 1
                        return if (window.innerWidth < needsTwoItemsWidth) 2 else 3
                    }

                    SimpleGrid(
                        modifier = Modifier.padding(all = 1.cssRem),
                        numColumns = numColumns(base = getAmountInGrid(), sm = 3, md = 3, lg = 4)
                    ) {
                        for ((url, image) in area.imageLinks) {
                            logger.trace("\tCreating image for $image")

                            GlassBox(
                                modifier = Modifier.size(65.px)
                                    .margin(all = 0.6.cssRem)
                            ) {
                                IconButtonNoHover(
                                    onClick = { ctx.router.navigateTo(url) }
                                ) {
                                    AppearanceAwareImage(
                                        src = image,
                                        noChange = true,
                                        modifier = Modifier.size(42.px).title(url),
                                        dropShadow = true
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun getToolBoxAreas(): List<ToolsBoxArea> = listOf(
    ToolsBoxArea("Programming Languages", PROGRAMMING_LANGUAGES) { Image(src = Res.AnimatedEmojis.SMILING_FACE_WITH_SUNGLASSES, modifier = Modifier.width(24.px)) },
    ToolsBoxArea("IDEs", IDEs) { Image(src = Res.AnimatedEmojis.DOTTED_LINE_FACE, modifier = Modifier.width(24.px)) },
    ToolsBoxArea("Software", SOFTWARE.toMutableList().apply { addAll(AI) }) { Image(src = Res.AnimatedEmojis.NERD_FACE, modifier = Modifier.width(24.px)) },
    ToolsBoxArea("Frameworks", FRAMEWORKs) { Image(src = Res.AnimatedEmojis.MAN_JUGGLING, modifier = Modifier.width(24.px)) },
    //    ToolsBoxArea("AI", AI) { Image(src = Res.AnimatedEmojis.ROBOT, modifier = Modifier.width(24.px)) },
)

// All IDEs you use.
private val IDEs: Collection<LinkableImage> =
    listOf(
        LinkableImage("https://www.jetbrains.com/idea/", Res.Software.INTELLIJ_LOGO),
        LinkableImage("https://www.jetbrains.com/clion/", Res.Software.CLION_LOGO),
        LinkableImage("https://www.jetbrains.com/resharper-cpp/", Res.Software.RESHARPER_CPP_LOGO),
        LinkableImage("https://www.jetbrains.com/resharper/", Res.Software.RESHARPER_CSHARP_LOGO),
        LinkableImage("https://code.visualstudio.com/", Res.Software.VSCODE_LOGO),
        LinkableImage("https://github.com/xored/f4", Res.Software.F4_IDE_LOGO),
        LinkableImage("https://developer.android.com/studio", Res.Software.ANDROID_LOGO),
    )

// Notable frameworks.
private val FRAMEWORKs: Collection<LinkableImage> =
    listOf(
        LinkableImage("https://kobweb.varabyte.com/", Res.Frameworks.KOBWEB_LOGO),
        LinkableImage("https://www.raylib.com/", Res.Frameworks.RAYLIB_LOGO),
        LinkableImage("https://junit.org/junit5/", Res.Frameworks.JUNIT5_LOGO),
        LinkableImage("https://square.github.io/kotlinpoet/", Res.Frameworks.KOTLIN_POET),
        LinkableImage("https://www.glfw.org/", Res.Frameworks.GLFW),
        LinkableImage("https://github.com/sheredom/utest.h", Res.Frameworks.UTEST),
        LinkableImage("https://opencv.org/", Res.Frameworks.OPENCV),
        LinkableImage("https://github.com/kordlib/kord", Res.Frameworks.KORD),
        LinkableImage("https://libgdx.com/", Res.Frameworks.LIBGDX),
        LinkableImage("https://gui-cs.github.io/Terminal.GuiV2Docs/index.html", Res.Frameworks.TERMINAL_GUI),
        LinkableImage("https://openjfx.io/", Res.Frameworks.JAVA_FX),
        LinkableImage("https://github.com/dotnet/wpf", Res.Frameworks.WPF_LOGO),
    )

// Other (relevant) software you use.
private val SOFTWARE: Collection<LinkableImage> =
    listOf(
        LinkableImage("https://git-scm.com/", Res.Software.GIT_LOGO),
        LinkableImage("https://www.gitkraken.com/git-client", Res.Software.GITKRAKEN_LOGO),
        LinkableImage("https://www.image-line.com/fl-studio/", Res.Software.FL_STUDIO_LOGO),
        LinkableImage("https://obsidian.md/", Res.Software.OBSIDIAN_MD_LOGO),
        LinkableImage("https://www.adobe.com/products/photoshop.html", Res.Software.PHOTOSHOP_LOGO),
        LinkableImage("https://www.adobe.com/products/premiere.html", Res.Software.PREMIERE_LOGO),
        LinkableImage("https://www.jetbrains.com/toolbox-app/", Res.Software.TOOLBOX_LOGO),
        LinkableImage("https://www.apachefriends.org/", Res.Software.XAMPP_LOGO),
        LinkableImage("https://f-droid.org/", Res.Software.FDROID_LOGO),
        LinkableImage("https://cydia.saurik.com/", Res.Software.CYDIA_LOGO),
    )

// Programming language you are proficient in.
private val PROGRAMMING_LANGUAGES: Collection<LinkableImage> =
    listOf(
        LinkableImage("https://en.wikipedia.org/wiki/C_(programming_language)", Res.Languages.C_LOGO),
        LinkableImage("https://en.wikipedia.org/wiki/C%2B%2B", Res.Languages.CPP_LOGO),
        LinkableImage("https://dotnet.microsoft.com/en-us/languages/csharp", Res.Languages.CSHARP_LOGO),
        LinkableImage("https://developer.mozilla.org/en-US/docs/Web/HTML", Res.Languages.HTML_LOGO),
        LinkableImage("https://dev.java/", Res.Languages.JAVA_LOGO),
        LinkableImage("https://kotlinlang.org/", Res.Languages.KOTLIN_LOGO),
        LinkableImage("https://www.markdownguide.org/", Res.Languages.MARKDOWN_LOGO),
        LinkableImage("https://fantom.org/", Res.Languages.FANTOM_LOGO),
    )

private val AI: Collection<LinkableImage> =
    listOf(
        LinkableImage("https://chat.openai.com/", Res.Software.CHAT_GPT_LOGO),
        LinkableImage("https://gemini.google.com/", Res.Software.GEMINI_LOGO),
    )

data class ToolsBoxArea(
    val name: String,
    val imageLinks: Iterable<LinkableImage> = mutableListOf(),
    val icon: @Composable () -> Unit
)

data class LinkableImage(val url: String, val imageSrc: String)