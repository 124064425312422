package com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.date

import com.pkg_dot_zip.kobwebportfoliopkgdotzip.Res

object SpecialDateHandler {

    fun getSpecialDates(): Set<SpecialDate> = setOf(
        SpecialDate(WebDate(months = 9, days = 16)).apply {
            title = "Portfolio birthday"
            specialDateText = "Happy Birthday to my portfolio!"
            frontPageEmoji = Res.AnimatedEmojis.PARTYING_FACE
        },

        SpecialDate(WebDate(months = 7, days = 25)).apply {
            title = "Kobweb birthday"
            specialDateText = "Happy Birthday to Kobweb!"
            frontPageEmoji = Res.AnimatedEmojis.PARTYING_FACE
        },

        SpecialDate(WebDate(months = 7, days = 25)).apply {
            title = "National Foundation Day in Japan"
            specialDateText = "Happy national foundation day Japan!"
            frontPageEmoji = Res.AnimatedEmojis.CROSSED_FLAGS
        },

        SpecialDate(WebDate(months = 5, days = 4, hours = 20)).apply {
            title = "Dodenherdenking"
            specialDateText = "Rust zacht aan alle slachtoffers van de oorlog."
            frontPageEmoji = Res.AnimatedEmojis.WHITE_FLAG
        },

        SpecialDate(WebDate(months = 4, days = 27)).apply {
            title = "Koningsdag"
            specialDateText = "Van Harte Gefeliciteerd Onze Majesteit!"
            frontPageEmoji = Res.SpecialEvents.KONINGSDAG
        }
    )

    fun <T> getValueOnSpecialDate(
        date: WebDate = WebDate.now(),
        valueSelector: (SpecialDate) -> T?
    ): T? {
        val specialDates = getSpecialDates()
        val valueMap = mutableMapOf<SpecialDate, Int>()

        for (specialDate in specialDates) {
            if (valueSelector(specialDate) == null) continue
            when {
                specialDate.date == date -> valueMap[specialDate] = 4
                specialDate.date.onSameDateAndYearAndHour(date) -> valueMap[specialDate] = 3
                specialDate.date.onSameDateAndYear(date) -> valueMap[specialDate] = 2
                specialDate.date.onSameDate(date) -> valueMap[specialDate] = 1
                else -> valueMap[specialDate] = 0
            }
        }

        val maxEntry = valueMap.maxByOrNull { it.value } // Find the entry with the maximum value.
        if (maxEntry == null || maxEntry.value == 0) return null // Return null if all values are 0.
        return maxEntry.key.let(valueSelector)
    }

    data class SpecialDate(
        var date: WebDate,
        var frontPageEmoji: String? = null,
        var specialDateText: String? = null,
        var title: String? = null,
    )
}