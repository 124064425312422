package com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.date

import com.pkg_dot_zip.kobwebportfoliopkgdotzip.Res
import com.pkg_dot_zip.kobwebportfoliopkgdotzip.util.Logger
import kotlin.js.Date

object EmojiBasedOnDate {
    private val logger = Logger("EmojiBasedOnDate")

    private fun getAvailableEmojis(): Collection<String> = setOf(
        Res.AnimatedEmojis.ASTONISHED_FACE,
        Res.AnimatedEmojis.NINJA,
        Res.AnimatedEmojis.HAMSA,
        Res.AnimatedEmojis.FACTORY_WORKER,
        Res.AnimatedEmojis.FIREFIGHTER,
        Res.AnimatedEmojis.MAN_BIKING,
        Res.AnimatedEmojis.MAN_DANCING,
        Res.AnimatedEmojis.MAN_SURFING,
        Res.AnimatedEmojis.MAN_CLIMBING,
        Res.AnimatedEmojis.MAN_JUGGLING,
        Res.AnimatedEmojis.MAN_BOUNCING_BALL,
        Res.AnimatedEmojis.MAN_CARTWHEELING,
        Res.AnimatedEmojis.MAN_IN_LOTUS_POSITION,
        Res.AnimatedEmojis.MAN_SWIMMING,
        Res.AnimatedEmojis.MAN_TECHNOLOGIST,
        Res.AnimatedEmojis.MAN_TIPPING_HAND,
        Res.AnimatedEmojis.SPEAKING_HEAD,
    )

    fun getEmojiRandomly(): String {
        logger.info("Retrieving Emoji at ${WebDate.now()}")

        val specialDateEmoji = SpecialDateHandler.getValueOnSpecialDate { it.frontPageEmoji }
        if (specialDateEmoji != null) return specialDateEmoji

        val availableEmojis = getAvailableEmojis()
        val randomIndex = availableEmojis.indices.random()
        logger.trace("Random index: $randomIndex")
        return availableEmojis.elementAt(randomIndex)
    }
}